.slideDown {
  height: auto;
  min-height: 300px;
  overflow-y: auto;
  transition: height 0.5s ease-out;
}

@keyframes slideDownAnimation {
  0% {
    height: 0;
    opacity: 1;
    transform: translateY(-5%);
  }
  100% {
    height: 100px;
    opacity: 1;
    transform: translateY(0);
  }
}

.slideDown {
  animation: slideDownAnimation 1.5s ease forwards;
}
